import "./styles.scss"

import React, { useEffect, useState } from "react"

const Popup = ({ data }) => {
  const [popup, setPopup] = useState(false)

  useEffect(() => {
    const isAcceptedSession = !!sessionStorage.getItem(
      `popup-marshall-real-estate-home`
    )
    !isAcceptedSession && setPopup(true)
  }, [])

  const acceptPopup = e => {
    e.preventDefault()
    sessionStorage.setItem(`popup-marshall-real-estate-home`, "active")
    setPopup(false)
  }

  return (
    data?.popupImage?.localFile?.publicURL && (
      <div className={`global-popup${popup ? " global-popup--open" : ""}`}>
        <div className="global-popup__wrapper">
          <button onClick={e => acceptPopup(e)}>
            <svg
              className={data?.popupCloseColor?.toLowerCase()}
              width="18.917"
              height="18.917"
              viewBox="0 0 18.917 18.917"
            >
              <g transform="translate(-22.793 -52.793)">
                <path
                  d="M-4314.1-7135l17.5,17.5"
                  transform="translate(4337.602 7188.5)"
                />
                <path
                  d="M0,0,17.5,17.5"
                  transform="translate(41.003 53.5) rotate(90)"
                />
              </g>
            </svg>
          </button>
          {data?.popupUrl ? (
            <a href={data?.popupUrl}>
              <img src={data?.popupImage?.localFile?.publicURL} alt="" />
            </a>
          ) : (
            <img src={data?.popupImage?.localFile?.publicURL} alt="" />
          )}
        </div>
      </div>
    )
  )
}

export default Popup
