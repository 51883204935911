import React from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import ContactGlobal from "components/ContactGlobal"

import {
  Hero,
  Numbers,
  Space,
  Search,
  Investments,
  Apartments,
  Office,
  References,
  Map,
  Popup,
} from "page_components/home"

const Home = ({ data }) => {
  return (
    <Layout
      seo={{
        title: "Inwestycje w nieruchomości premium",
        description:
          "Inwestycje w apartamenty premium. Zarabiaj inwestując w apartamenty inwestycyjne, hotelowe, komercyjne.",
      }}
      isHome
    >
      <Hero />
      <Numbers data={data?.allWpPage?.nodes[0]?.acfHome} />
      <Space />
      <Search />
      <Investments />
      <Apartments />
      <Office />
      <References data={data?.allWpPage?.nodes[0]?.acfHome} />
      <Map />
      <ContactGlobal />
      <Popup data={data?.allWpPage?.nodes[0]?.acfHome} />
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpPage(filter: { id: { eq: "cG9zdDo5MjQ=" } }) {
      nodes {
        acfHome {
          numbersContent {
            number
            label
            description
          }
          referencesContent {
            author
            content
          }
          popupUrl
          popupCloseColor
          popupImage {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default Home
